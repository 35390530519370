.app-container {
  max-width: 100% !important;
  zoom: 0.8;
  padding: 2rem;
  background-color: #091823;
}

.users-app-container {
  max-width: 100% !important;
  zoom: 0.8;
  padding: 2rem;
  background-color: #091823;
}

.settings-container {
  max-width: 100% !important;
  zoom: 0.8;
  padding: 2rem;
  background-color: #091823;
  min-height: 125vh !important;
}
.landing-container {
  max-width: 100% !important;
  padding: 0px !important;
}
.contact-us-container {
  max-width: 100% !important;
  zoom: 0.8;
  padding: 2rem;
  background-color: #091823;
  min-height: 125vh !important;
}
.create-user-container {
  max-width: 100% !important;
  padding: 2rem;
  zoom: 0.8;
  background-color: #091823;
  min-height: 125vh !important;
}
.create-user-container .form-signin form {
  top: 55%;
}
.login-container {
  max-width: 100% !important;
  padding: 2rem;
  background-color: #091823;
  min-height: 100vh !important;
}

.contact-us-container .row {
  margin-top: 10%;
}
.contact-us-container .card {
  /* width: 80%; */
}

.not-found-container img {
  cursor: pointer;
}

.form-signin img {
  cursor: pointer;
  width: 100%;
}

.users-table-container {
  height: 100vh; /* Full viewport height */
  display: flex;
  flex-direction: column;
}

.users-table-container .ag-theme-alpine {
  flex: 1;
  height: 100%; /* Ensure the grid fills the container */
}

.users-app-container .thumb-pointer {
  cursor: pointer;
}

.type-text-container textarea {
  min-height: 56vh !important;
}

.single-user {
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.45);
  /* zoom: 0.8; */
}

.speaking-styles-row button {
  width: 23%;
}

.user-picture {
  display: inline-block;
  width: 45px;
  height: 45px;
  border: 2px solid lightblue;
  border-radius: 10rem;
}

.single-user .user-picture.male {
  background: url("./images/male.png");
  height: 45px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.single-user.active {
    background-color: #124c5924;
}

.btn.btn-primary {
  background-color: #124c59;
  border-color: #124c59;
}
.btn.speaking-style-btn {
  background-color: #bacad9;
  border-color: #bacad9;
  color: black;
}

.table-responsive {
  max-height: 400px; /* Adjust this height as needed */
  overflow-y: auto;
}

.single-user .user-picture.female {
  background: url("./images/female.png");
  height: 45px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.single-user {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 260px;
}

.user-description {
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 210px;
  height: 100px;
}

.users-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
}

.card-custom {
  flex: 0 0 45%;
  box-sizing: border-box;
  margin-right: 1rem;
}

.users-container::-webkit-scrollbar {
  height: 12px;
}

.users-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.users-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.user-language {
  display: block !important;
  width: fit-content;
}

.user-bio {
  display: inline-block;
  margin-left: 5px;
  vertical-align: super;
}
.user-bio p {
  display: inline-block;
  margin-bottom: 0rem;
}

.main-container {
  margin-top: 100px;
}

.fa-microphone {
  color: #14a76c;
}

.fa-microphone:hover {
  color: #0c6e47;
}

.fa-volume-up {
  color: gold;
}

.fa-volume-up:hover {
  color: darkgoldenrod;
}

.fa-volume-mute {
  color: white;
}

.fa-volume-mute:hover {
  color: beige;
}

.fa-file-audio {
  color: #ff652f;
}

.fa-file-audio:hover {
  color: #d44916;
}

.output-display {
  background-color: #383b4b;
  height: 700px;
}

.fas,
.fa {
  cursor: pointer;
}

.background {
  background-color: #272727;
  color: #747474;
}



.contact-us-container {
  min-height: 100vh;
}
.settings-container {
  min-height: 100vh;
}


/* Login CSS */
.form-signin {
    /* max-width: 430px; */
    max-width: 45vh;
    /* padding: 1rem; */
  }
  .form-signin form {
    width: 48vh;
    margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  }
  
  .form-signin .form-floating:focus-within {
    z-index: 2;
  }
  
  .form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .settings-container .form-signin {
    max-width: 30%;
  }
  .settings-container .form-signin form {
    width: 30%;
  }

@media only screen and (max-width: 600px) {
  .type-text-container textarea {
    min-height: 300px !important;
  }
  .speaking-styles-row button {
    width: 45% !important;
  }
  .configuration-container {
    margin-top: 2rem;
  }
  .card-custom {
    flex: 0 0 50%;
  }
  .user-bio {
    display: block;
    margin-left: 5px;
    vertical-align: super;
    margin-left: 0px !important;
  }
  .navbar-brand {
    width: 50%;
  }
  .navbar-brand img {
    width: 100%;
  }
  .chars-words-count .col-6:nth-child(1) {
    display: none;
  }
  .chars-words-count .col-6 {
    text-align: center !important;
  }
  .contact-us-container .card {
    width: 100%;
  }
  .contact-us-container .brands-container svg {
    font-size: 5em;
    margin-bottom: 3rem !important;
  }
  .settings-container .form-signin {
    max-width: 100%;
  }
  .settings-container .form-signin form {
    width: 80%;
  }
  .create-user-container .form-signin form {
    width: 86%;
    top: 55%;
  }
  .form-signin form {
    width: 83%;
  }
  .form-signin img {
    width: 100%;
  }
}